export function isVersionGreater(previousVersion: string, currentVersion: string) {
    const prevVersionNums = previousVersion.split('.').map(Number);
    const currVersionNums = currentVersion.split('.').map(Number);

    for (let i = 0; i < Math.max(prevVersionNums.length, currVersionNums.length); i++) {
        const prev = i < prevVersionNums.length ? prevVersionNums[i] : 0;
        const curr = i < currVersionNums.length ? currVersionNums[i] : 0;

        if (curr > prev) {
            return true;
        } else if (curr < prev) {
            return false;
        }
    }

    return false;
}