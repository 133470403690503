'use client';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

if (typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_INIT as string, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_URL,
    capture_pageview: false, // Disable automatic pageview capture, as we capture manually
  });
}

export function NextPostHogProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
