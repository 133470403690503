'use client';
import { useEffect } from "react";
import { getAppVersionFromCookie, getVanityNameFromCookie, saveAppVersionInCookie } from "./action";
import { isVersionGreater } from '@utils/versionChecker'
import { clearCache } from "@modules/cacheCall";
import { useBoundStore } from "@store/index";

const AppVersionControl = () => {
    const {selectedBusiness} = useBoundStore()

    useEffect(() => {
        const checkAppVersion = async () => {
            const vanityName = await getVanityNameFromCookie();
            const appVersion = await getAppVersionFromCookie();
            if (vanityName) {

                if (appVersion === null) {
                    if(selectedBusiness?.id) clearBrowserCache(selectedBusiness?.id)
                    await saveAppVersionInCookie(process.env.NEXT_PUBLIC_APP_VERSION || '1.0.0');


                } else if (
                    process.env.NEXT_PUBLIC_APP_VERSION &&
                    isVersionGreater(appVersion, process.env.NEXT_PUBLIC_APP_VERSION)
                ) {
                    if(selectedBusiness?.id) clearBrowserCache(selectedBusiness?.id)
                    await saveAppVersionInCookie(process.env.NEXT_PUBLIC_APP_VERSION);
                }
            }
        };

        checkAppVersion();
    }, []);

    const clearBrowserCache = async (business_id:string) => {
        try {
            const response = await fetch('/api/clearCache', {
                method: 'POST',
            });
            await clearCache(business_id)
            if (response.ok) {
                console.log('Cache clear successful');
            } else {
                console.error('Cache clear failed');
            }
        } catch (error) {
            console.error('An error occurred during logout', error);
        }
    };

    return <></>;
};

export default AppVersionControl;