'use client';
import { AppProgressBar as ProgressBar } from 'next-nprogress-bar';

interface Props {
  children: React.ReactNode;
}

const NextNProgressBarProvider = ({ children }: Props) => {
  return (
    <>
      {children}
      <ProgressBar
        height="4px"
        color="#2F3B9E"
        options={{ showSpinner: false }}
        shallowRouting
      />
    </>
  );
};

export default NextNProgressBarProvider;
